/* eslint-disable import/prefer-default-export */
import transport from './transport';
import * as methods from '../constants';

/**
 * Гайд по именованию ресурсов
 *
 * @see: https://burning-heart.atlassian.net/wiki/spaces/front/pages/1375109156/JavaScript
 */

/*
 * Авторизационная точка входа
 *
 * @example: connectAuthorize();
 */
export const connectAuthorize = ({ oidc, ...options } = {}) => transport({
  url: `/connect/authorize-complete?oidc=${oidc}&not-redirect=true`,
  method: methods.HTTP_GET,
  ...options,
});
