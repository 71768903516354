<template>
  <VTooltip
    :data-test="dataTest"
    :data-test-label="dataTestLabel"
    :data-test-value="dataTestValue"

    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
  >
    <slot />

    <template #activator="data">
      <slot
        name="activator"
        v-bind="data"
      />
    </template>
  </VTooltip>
</template>

<script>

export default {
  name: 'TTTooltip',

  props: {
    /* New */
    dataTest: {
      type: String,
      default: 'tt-tooltip',
    },
    dataTestLabel: {
      type: String,
      default: '',
    },
    dataTestValue: {
      type: String,
      default: '',
    },

    /* Vuetify */
    absolute: {
      type: Boolean,
      default: false,
    },
    activator: {
      type: [String, Object],
      default: undefined,
    },
    allowOverflow: {
      type: Boolean,
      default: true,
    },
    attach: {
      type: [String, Object, Boolean],
      default: false,
    },
    bottom: {
      type: Boolean,
    },
    closeDelay: {
      type: [Number, String],
      default: 0,
    },
    color: {
      type: String,
      default: undefined,
    },
    contentClass: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
    },
    eager: {
      type: Boolean,
    },
    fixed: {
      type: Boolean,
      default: true,
    },
    internalActivator: {
      type: Boolean,
    },
    left: {
      type: Boolean,
    },
    maxWidth: {
      type: [Number, String],
      default: 'auto',
    },
    minWidth: {
      type: [Number, String],
      default: undefined,
    },
    nudgeBottom: {
      type: [Number, String],
      default: 0,
    },
    nudgeLeft: {
      type: [Number, String],
      default: 0,
    },
    nudgeRight: {
      type: [Number, String],
      default: 0,
    },
    nudgeTop: {
      type: [Number, String],
      default: 0,
    },
    nudgeWidth: {
      type: [Number, String],
      default: 0,
    },
    offsetOverflow: {
      type: Boolean,
    },
    openDelay: {
      type: [Number, String],
      default: 300,
    },
    openOnClick: {
      type: Boolean,
      default: false,
    },
    openOnFocus: {
      type: Boolean,
      default: false,
    },
    openOnHover: {
      type: Boolean,
      default: true,
    },
    positionX: {
      type: Number,
      default: undefined,
    },
    positionY: {
      type: Number,
      default: undefined,
    },
    right: {
      type: Boolean,
    },
    tag: {
      type: String,
      default: 'span',
    },
    top: {
      type: Boolean,
    },
    transition: {
      type: [String],
      default: 'fade-transition',
    },
    value: {
      type: [String, Number, Boolean],
      default: undefined,
    },
    zIndex: {
      type: [Number, String],
      default: undefined,
    },
  },
};
</script>

<style lang="scss" src="./TTTooltip.scss"></style>
