export const foo = 'bar';
export const redirectUrl = {
  ARCHIVIST_ADDRESS: process.env.VUE_APP_ARCHIVIST_ADDRESS,
};

export const LOGIN_STEPS = {
  phone: 'phone',
  code: 'code',
};

export const errorsMatchRegisterUser = {
  'phone is required': 'Телефон - обязательное поле',
  'login already exists': 'Пользователь с таким логином уже существует',
  'phone already exists': 'Пользователь с таким телефоном уже существует',
  'email already exists': 'Пользователь с таким e-mail уже существует',
};

export const INVITATION_DEFAULT_LINK = {
  AGREEMENT: 'https://storage.talenttech.ru/public/files/Policies/agreement.pdf',
  POLICY: 'https://storage.talenttech.ru/public/files/Policies/policy.pdf',
};
