import Vue from 'vue';
// IMPORTANT: пришлось скопировать, т.к. имена компонетов зашиты намертво.
// Переопределить их в списке VTextField : TTTextField - ломает сами поля.
// Не форматируйте эти файлы !!!
import VJsf from '@/plugins/vue-json-schema-form/lib/VJsf';
import '@/plugins/vue-json-schema-form/lib/VJsf.css';
// load third-party dependencies (markdown-it, vuedraggable)
// you can also load them separately based on your needs
// import '@/plugins/vue-json-schema-form/lib/deps/third-party';

Vue.component('VJsf', VJsf);
