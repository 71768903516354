import * as cookies from '@/services/cookies';
import * as publicApi from '@/services/api/public-api';
import { redirectUrl } from '@/constants';
import {
  R_INVITATION, R_ABOUT, R_AUTH_SAP_SSO, R_PRIVACY_POLICIES, R_MOBILE_LEARNING, R_NOT_FOUND,
} from '@/plugins/vue-router/routeNames';

export default async function publicRoutes(to, from, next) {
  const publicRoute = [
    R_INVITATION, R_ABOUT, R_AUTH_SAP_SSO, R_PRIVACY_POLICIES, R_MOBILE_LEARNING, R_NOT_FOUND,
  ];

  if (!publicRoute.includes(to.name)) {
    const token = cookies.accessToken.get();
    const company = cookies.companyId.get();
    if (token && company) {
      const { oidc } = to.query;
      if (oidc) {
        const { headers } = await publicApi.connectAuthorize({ oidc });
        window.location.replace(headers.location);
      } else {
        window.location.replace(redirectUrl.ARCHIVIST_ADDRESS);
      }
    }
  }

  return next();
}
