<template>
  <VContainer :class="fillHeightClass">
    <VRow
      class="mt-3"
      align="start"
      :class="fillHeightClass"
    >
      <VCol
        class="d-none d-sm-flex"
        sm="6"
        md="1"
      >
        <!-- left buttons -->
        <!-- Note: button should be wrapped:
          <VRow>
            <VCol>
              BUTTON HERE
            </VCol>
          </VRow>
        -->
        <slot name="left" />
      </VCol>

      <VCol
        class="d-none d-sm-flex"
        sm="6"
        md="1"
        order-md="last"
      >
        <!-- right buttons -->
        <!-- Note: button should be wrapped:
          <VRow>
            <VCol>
              BUTTON HERE
            </VCol>
          </VRow>
        -->
        <slot name="right" />
      </VCol>

      <VCol
        md="10"
        cols="12"
        :class="fillHeightClass"
      >
        <!-- center content -->
        <!-- Note: content should be wrapped:
          <VRow>
            <VCol>
              CONTENT HERE
            </VCol>
          </VRow>
        -->
        <slot v-if="!offset" />
        <VRow
          v-else
          no-gutters
        >
          <VCol
            :cols="$vuetify.breakpoint.smAndUp ? 10 : 12"
            :offset="$vuetify.breakpoint.smAndUp ? 1 : 0"
          >
            <slot />
          </VCol>
        </VRow>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
export default {
  name: 'TTView',

  props: {
    offset: {
      type: Boolean,
      default: false,
    },
    fillHeight: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    fillHeightClass() {
      return { 'fill-height': this.fillHeight };
    },
  },
};
</script>
