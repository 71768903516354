import Vue from 'vue';
import VueRouter from 'vue-router';
import createRouter from '@/plugins/vue-router/router';
import routes from '@/plugins/vue-router/routes';
import setTitle from '@/plugins/vue-router/guards/setTitle';
import publicRoutes from '@/plugins/vue-router/guards/checkOidc';

Vue.use(VueRouter);

const { BASE_URL: baseUrl } = process.env;

const router = createRouter({
  baseUrl,
  routes,
});

router.beforeEach(setTitle);
router.beforeEach(publicRoutes);

export * from '@/plugins/vue-router/routeNames';
export default router;
