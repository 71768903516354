<template>
  <VToolbar
    :data-test="dataTest"
    :data-test-label="dataTestLabel"
    :data-test-value="dataTestValue"
    :absolute="absolute"
  >
    <slot />
  </VToolbar>
</template>

<script>
export default {
  name: 'TTToolbar',
  props: {
    dataTest: {
      type: String,
      default: 'tt-toolbar',
    },
    dataTestLabel: {
      type: String,
      default: '',
    },
    dataTestValue: {
      type: String,
      default: '',
    },

    absolute: Boolean,
  },
};
</script>

<style scoped lang="scss">

</style>
