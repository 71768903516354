import * as Names from '@/plugins/vue-router/routeNames';

const routes = [
  {
    path: '/app',
    component: () => import('@/layouts/app/FormLayout.vue'),
    children: [
      {
        path: '',
        name: Names.R_APP,
        component: () => import('@/views/FormView.vue'),
        redirect: { name: Names.R_AUTH_SIGN_IN },
        children: [
          {
            path: 'sign-in',
            name: Names.R_AUTH_SIGN_IN,
            components: {
              default: () => import(/* webpackChunkName: "[request]" */ '@/views/Authentication/SignIn.vue'),
            },
          },
          {
            path: 'invitations/:invitationId',
            name: Names.R_INVITATION,
            components: {
              default: () => import(/* webpackChunkName: "[request]" */ '@/views/Invitation/InvitationPage.vue'),
            },
            meta: {
              title: 'Регистрация',
            },
          },
          {
            path: 'about',
            redirect: { name: Names.R_ABOUT },
          },
        ],
      },
    ],
  },

  {
    path: '/',
    component: () => import('@/layouts/DefaultLayout.vue'),
    children: [
      {
        path: '',
        redirect: { name: Names.R_AUTH_SIGN_IN },
      },
      {
        path: 'about',
        name: Names.R_ABOUT,
        components: {
          default: () => import(/* webpackChunkName: "[request]" */ '@/views/AboutPage.vue'),
        },
      },
      {
        path: 'auth/sso/:sapId/:ticket',
        name: Names.R_AUTH_SAP_SSO,
        components: {
          default: () => import(/* webpackChunkName: "[request]" */ '@/views/Authentication/SapSSO.vue'),
        },
      },

      {
        path: 'privacy-policy',
        name: Names.R_PRIVACY_POLICIES,
        components: {
          default: () => import(/* webpackChunkName: "[request]" */ '@/views/PrivacyPolicies.vue'),
        },
      },
    ],
  },

  {
    path: '/mobile',
    component: () => import('@/layouts/DefaultLayout.vue'),
    children: [
      {
        path: '',
        redirect: { name: Names.R_MOBILE_LEARNING },
      },

      {
        path: 'learning',
        name: Names.R_MOBILE_LEARNING,
        components: {
          default: () => import(/* webpackChunkName: "[request]" */ '@/views/Mobile/LearningPage.vue'),
        },
      },
    ],
  },

  {
    path: '*',
    name: Names.R_NOT_FOUND,
    component: () => import(/* webpackChunkName "[request]" */ '@/views/NotFound.vue'),
  },
];

export default routes;
