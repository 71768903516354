/* eslint-disable quote-props, key-spacing, no-multi-spaces */

/*
 * IMPORTANT: сменил цвета здесь, смени и в `styles/_tt.colors.scss`
 *
 * @see: https://burning-heart.atlassian.net/wiki/spaces/front/pages/1616446310
 * @see: https://www.figma.com/file/8uTFijimkGLRPoLQMnUuyL/Main-2?node-id=3995%3A296
 */

const TTColors = {
  'tt-light-mono-100':  { base: '#0B1218' },  /*  Light  Mono  100  */
  'tt-light-mono-64':   { base: '#63676B' },  /*  Light  Mono  64   */
  'tt-light-mono-46':   { base: '#8F9295' },  /*  Light  Mono  46   */
  'tt-light-mono-24':   { base: '#C4C6C8' },  /*  Light  Mono  24   */
  'tt-light-mono-16':   { base: '#D8D9DA' },  /*  Light  Mono  16   */
  'tt-light-mono-8':    { base: '#EBECED' },  /*  Light  Mono  8    */
  'tt-light-mono-4':    { base: '#F5F6F6' },  /*  Light  Mono  4    */
  'tt-light-mono-0':    { base: '#FFFFFF' },  /*  Light  Mono  0    */

  'tt-light-blue': {
    'base':     '#00A0F2',  /*  Light  Blue  Base     */
    'vibrant':  '#00B8FF',  /*  Light  Blue  Vibrant  */
    'reduced':  '#6AD9FF',  /*  Light  Blue  Reduced  */
    'pale':     '#D9F5FF',  /*  Light  Blue  Pale     */
  },
  'tt-light-red': {
    'base':     '#EF323F',  /*  Light  Red  Base     */
    'vibrant':  '#FF2B3E',  /*  Light  Red  Vibrant  */
    'reduced':  '#FF4754',  /*  Light  Red  Reduced  */
    'pale':     '#FFE2E2',  /*  Light  Red  Pale     */
  },
  'tt-light-green': {
    'base':     '#00A345',  /*  Light  Green  Base     */
    'vibrant':  '#00D358',  /*  Light  Green  Vibrant  */
    'reduced':  '#00F170',  /*  Light  Green  Reduced  */
    'pale':     '#D4F8E7',  /*  Light  Green  Pale     */
  },
  'tt-light-yellow': {
    'base':     '#FFB500',  /*  Light  Yellow  Base     */
    'vibrant':  '#FFC700',  /*  Light  Yellow  Vibrant  */
    'reduced':  '#FFD340',  /*  Light  Yellow  Reduced  */
    'pale':     '#FFF6DC',  /*  Light  Yellow  Pale     */
  },
  'tt-light-orange': {
    'base':     '#FF3E00',  /*  Light  Orange  Base     */
    'vibrant':  '#FF6E37',  /*  Light  Orange  Vibrant  */
    'reduced':  '#FF9974',  /*  Light  Orange  Reduced  */
    'pale':     '#FFEAE3',  /*  Light  Orange  Pale     */
  },
  'tt-light-purple': {
    'base':     '#601EF3',  /*  Light  Purple  Base     */
    'vibrant':  '#8455F8',  /*  Light  Purple  Vibrant  */
    'reduced':  '#A886FB',  /*  Light  Purple  Reduced  */
    'pale':     '#EDE5FE',  /*  Light  Purple  Pale     */
  },
  'tt-light-pink': {
    'base':     '#FF00D7',  /*  Light  Pink  Base     */
    'vibrant':  '#FF26E1',  /*  Light  Pink  Vibrant  */
    'reduced':  '#FF70EA',  /*  Light  Pink  Reduced  */
    'pale':     '#FFE2FC',  /*  Light  Pink  Pale     */
  },

  'tt-dark-mono-100':  { base: '#FFFFFF' },  /*  Dark  Mono  100  */
  'tt-dark-mono-96':   { base: '#F5F6F6' },  /*  Dark  Mono  96   */
  'tt-dark-mono-76':   { base: '#C4C6C8' },  /*  Dark  Mono  76   */
  'tt-dark-mono-64':   { base: '#A7AAAC' },  /*  Dark  Mono  64   */
  'tt-dark-mono-46':   { base: '#7B7F82' },  /*  Dark  Mono  46   */
  'tt-dark-mono-24':   { base: '#464B4F' },  /*  Dark  Mono  24   */
  'tt-dark-mono-16':   { base: '#32383D' },  /*  Dark  Mono  16   */
  'tt-dark-mono-8':    { base: '#1F252A' },  /*  Dark  Mono  8    */
  'tt-dark-mono-4':    { base: '#151B21' },  /*  Dark  Mono  4    */
  'tt-dark-mono-0':    { base: '#0B1218' },  /*  Dark  Mono  0    */

  'tt-dark-blue': {
    'base':     '#00A0F2',  /*  Dark  Blue  Base     */
    'vibrant':  '#00B8FF',  /*  Dark  Blue  Vibrant  */
    'reduced':  '#6AD9FF',  /*  Dark  Blue  Reduced  */
    'pale':     '#D9F5FF',  /*  Dark  Blue  Pale     */
  },
  'tt-dark-red': {
    'base':     '#EF323F',  /*  Dark  Red  Base     */
    'vibrant':  '#FF2B3E',  /*  Dark  Red  Vibrant  */
    'reduced':  '#FF4754',  /*  Dark  Red  Reduced  */
    'pale':     '#FFE2E2',  /*  Dark  Red  Pale     */
  },
  'tt-dark-green': {
    'base':     '#00A345',  /*  Dark  Green  Base     */
    'vibrant':  '#00D358',  /*  Dark  Green  Vibrant  */
    'reduced':  '#00F170',  /*  Dark  Green  Reduced  */
    'pale':     '#D4F8E7',  /*  Dark  Green  Pale     */
  },
  'tt-dark-yellow': {
    'base':     '#FFB500',  /*  Dark  Yellow  Base     */
    'vibrant':  '#FFC700',  /*  Dark  Yellow  Vibrant  */
    'reduced':  '#FFD340',  /*  Dark  Yellow  Reduced  */
    'pale':     '#FFF6DC',  /*  Dark  Yellow  Pale     */
  },
  'tt-dark-orange': {
    'base':     '#FF3E00',  /*  Dark  Orange  Base     */
    'vibrant':  '#FF6E37',  /*  Dark  Orange  Vibrant  */
    'reduced':  '#FF9974',  /*  Dark  Orange  Reduced  */
    'pale':     '#FFEAE3',  /*  Dark  Orange  Pale     */
  },
  'tt-dark-purple': {
    'base':     '#601EF3',  /*  Dark  Purple  Base     */
    'vibrant':  '#8455F8',  /*  Dark  Purple  Vibrant  */
    'reduced':  '#A886FB',  /*  Dark  Purple  Reduced  */
    'pale':     '#EDE5FE',  /*  Dark  Purple  Pale     */
  },
  'tt-dark-pink': {
    'base':     '#FF00D7',  /*  Dark  Pink  Base     */
    'vibrant':  '#FF26E1',  /*  Dark  Pink  Vibrant  */
    'reduced':  '#FF70EA',  /*  Dark  Pink  Reduced  */
    'pale':     '#FFE2FC',  /*  Dark  Pink  Pale     */
  },

  /*
   * TODO: START DELETE
   *
   * @deprecated: Не используем!!!
   */
  'tt-blue': {
    base: '#00A0F2' /* Blue / Dark Blue */,
    lighten1: '#00B8FF' /* Blue / Blue */,
    lighten2: '#6AD9FF' /* Blue / Light Blue */,
    lighten3: '#D9F5FF' /* Blue / Extra Light Blue */,
  },

  'tt-red': {
    base: '#EF323F' /* Red / Dark Red */,
    lighten1: '#FF2B3E' /* Red / Red */,
    lighten2: '#FF4754' /* Red / Light Red */,
    lighten3: '#FFE2E2' /* Red / Extra Light Red */,
  },

  'tt-green': {
    base: '#00A345' /* Green / Dark Green */,
    lighten1: '#00D358' /* Green / Green */,
    lighten2: '#00F170' /* Green / Light Green */,
    lighten3: '#D4F8E7' /* Green / Extra Light Green */,
  },

  'tt-yellow': {
    base: '#FFB500' /* Yellow / Dark Yellow */,
    lighten1: '#FFC700' /* Yellow / Yellow */,
    lighten2: '#FFD340' /* Yellow / Light Yellow */,
    lighten3: '#FFF6DC' /* Yellow / Extra Light Yellow */,
  },

  'tt-orange': {
    base: '#FF3E00' /* Orange / Dark Orange */,
    lighten1: '#FF6E37' /* Orange / Orange */,
    lighten2: '#FF9974' /* Orange / Light Orange */,
    lighten3: '#FFEAE3' /* Orange / Extra Light Orange */,
  },

  'tt-purple': {
    base: '#601EF3' /* Purple / Dark Purple */,
    lighten1: '#8455F8' /* Purple / Purple */,
    lighten2: '#A886FB' /* Purple / Light Purple */,
    lighten3: '#EDE5FE' /* Purple / Extra Light Purple */,
  },

  'tt-pink': {
    base: '#FF00D7' /* Pink / Dark Pink */,
    lighten1: '#FF26E1' /* Pink / Pink */,
    lighten2: '#FF70EA' /* Pink / Light Pink */,
    lighten3: '#FFE2FC' /* Pink / Extra Light Pink */,
  },

  'tt-black': {
    base:      '#0B1218',  /*  Black  /  Black  100  */
    lighten1:  '#63676B',  /*  Black  /  Black  64   */
    lighten2:  '#8F9295',  /*  Black  /  Black  46   */
    lighten3:  '#C4C6C8',  /*  Black  /  Black  24   */
    lighten4:  '#D8D9DA',  /*  Black  /  Black  16   */
    lighten5:  '#EBECED',  /*  Black  /  Black  8    */
    lighten6:  '#F5F6F6',  /*  Black  /  Black  4    */
    lighten7:  '#FFFFFF',  /*  White  /  White  100  */
  },

  'tt-white': {
    base:     '#FFFFFF',  /*  White  /  White  100  */
    darken1:  '#F5F6F6',  /*  Black  /  Black  4    */
    darken2:  '#EBECED',  /*  Black  /  Black  8    */
    darken3:  '#D8D9DA',  /*  Black  /  Black  16   */
    darken4:  '#C4C6C8',  /*  Black  /  Black  24   */
    darken5:  '#8F9295',  /*  Black  /  Black  46   */
    darken6:  '#63676B',  /*  Black  /  Black  64   */
    darken7:  '#0B1218',  /*  Black  /  Black  100  */
  },
  /*
   * TODO: END DELETE
   */
};

export default TTColors;
