import ru from 'vuetify/es5/locale/ru';

export default {
  ...ru,
  TTAlert: {
    actions: {
      cancel: 'Отмена',
      delete: 'Удалить',
      save: 'Сохранить',
    },
  },
  AppProfileMenu: {
    sign_out: 'Выйти',
  },
};
