import camelCase from 'lodash/camelCase';

const requireModule = require.context('.', false, /\.js$/);
const modules = {};

requireModule.keys().forEach((f) => {
  if (f === './index.js') return;
  const moduleName = camelCase(f.replace(/(\.\/|\.js)/g, ''));
  modules[moduleName] = requireModule(f).default;
});

export default modules;
