import { Store } from 'vuex';
import { toggleSnack, toggleLoading, toggleNavDrawer } from '@/plugins/vuex/mutationTypes';
import modules from '@/plugins/vuex/modules';
import plugins from '@/plugins/vuex/plugins';

const strict = process.env.NODE_ENV === 'development';

function createStore() {
  const store = new Store({
    strict,
    plugins,
    modules,

    // TODO: refactor use modules
    state: {
      loading: false,
      navDrawer: false,
      snackbar: {
        value: false,
        message: '',
        color: '',
        timeout: 3000,
      },
    },

    actions: {},

    mutations: {
      [toggleLoading](state, payload) {
        state.loading = payload;
      },

      [toggleNavDrawer](state, payload) {
        state.navDrawer = payload;
      },

      [toggleSnack](state, payload) {
        const { snackbar } = state;
        snackbar.value = !snackbar.value;
        snackbar.message = payload.message || '';
        snackbar.color = payload.color || '';
      },
    },

    getters: {},
  });

  return store;
}

export default createStore;
