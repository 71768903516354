import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

if (process.env.VUE_APP_SENTRY_ENABLE) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV,
    integrations: [
      new BrowserTracing(),
    ],
    attachProps: true,
    logErrors: true,
    tracesSampleRate: 1.0,
  });
}
